.center-text {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-align: center;
}

.button {
    font-size: 20px;
    margin: 0 auto;
    transition: all .2s ease-in-out; 
    padding: 10px;
    text-decoration: none;
    color: #ffcb04;
    background-color: #9b0000;
    border: 5px solid;
    border-radius: 20px;
    border-color: #a80000;
    box-shadow: 0 0 10px #ff3c00e3;
}

.button:hover {
    transform: scale(1.1) !important; 
    background-color: #6e0000;
    color: #fff1a2;
    border-color: #770000;
    box-shadow: 0 0 10px #9b2400e3;
}

.button-div {
    transition: all .3s ease-in-out; 
    padding: 20px;
    display: inline-block;
}

.button-div:hover {
    transform: scale(1.1) !important; 

}