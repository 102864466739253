/*NAVBAR CUSTOM CSS*/
.nav-bar {
    background-color: #9b0000;
    border-bottom: 2px solid #dadada;
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
}

#nav-top {
    margin-left: auto !important;   
}

/*NAVBAR Button*/

.nav-button {
    text-decoration: none !important;
    background-color: #7289DA;
    border-radius: 8px;
}

.nav-button:hover {
    text-decoration: none !important;
    background-color: #304db8;
}

/*NAVBAR TEXT*/

.nav-text:hover {
    text-decoration: none !important;
    color: #fff1a2 !important;
}

.nav-text:focus {
    color: #fff1a2 !important;
}

.nav-text {
    text-decoration: none !important;
    text-decoration: none !important;
    color: #ffcb04 !important;
}

.nav-text:active {
    text-decoration: none !important;
    color: #a76700 !important;
}

.nav-title {
    text-decoration: none;
    color: white;
    font-weight: bold;
}

.nav-title {
    color: white !important;
}

.nav-selections {
    margin-left: 50px;

}


/* PC Broswer*/

@media only screen and (min-width:576px) {
    .nav-text {
        font-size: 25px;
    }
    .nav-title {
        font-size: 35px
    }
    .nav-selections {
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

}


/* Mobile Broswer*/

@media only screen and (max-width: 575px) {
    .nav-text {
        font-size: 20px;
    }
    .nav-title {
        font-size: 25px
    }
}