.App-base {
    background-color: rgb(31, 31, 31);
    background-size: cover;
    color: white;
}



.App-logo {
    pointer-events: none;
}


/*PC brower*/

@media only screen and (min-width:576px) {
    .App-logo {
        height: 75px;
    }
}


/*Mobile brower*/

@media only screen and (max-width: 575px) {
    .App-logo {
        height: 50px;
    }
}


