.title-text {
    padding: 50px 50px;
    position: relative;
    text-align: center;
    color: white;
    font-weight: bold;
}

.title-desc {
    position: relative;
    text-align: center;
    color: white;
}

.title-box {
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 100px 25px;
    border: 2px solid #000000;
    border-radius: 20px;
    outline: none;
    border-color: #c8c8c86e;
    box-shadow: 0 0 10px #f8f8f85b;
}


/*PC brower*/

@media only screen and (min-width:576px) {
    .title-desc {
        font-size: 30px;
    }

    .title-text {
        font-size: 65px;
    }
    .title-box {
        width: 600px;
    }

}


/*Mobile brower*/

@media only screen and (max-width: 575px) {
    .title-desc {
        font-size: 15px;
    }

    .title-text {
        font-size: 35px;
    }
    .title-box {
        width: 300px;
    }

}