.linkedin-box {
    padding: 20px;
    justify-content: center;
    display: flex;

}

.center-text {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-align: center;
}